/* You can add global styles to this file, and also import other style files */
@import url('@scania/tegel/dist/tegel/tegel.css');
// @import '~@angular/material/prebuilt-themes/indigo-pink.css';


.ngx-pagination {
  background-color: white;
}

svg>title {
  display: none;
}

app-root {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

body {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

.contabil-page {
  //background-color: var(--sdds-grey-50);
  padding-top: 90px !important;

  //margin-left: 0;
  h2 {
    margin-bottom: 32px;
  }

  .divider-line {
    border-top: 1px solid #DBDFE6;
    margin-bottom: 32px;
  }

  p {
    opacity: 0.6;
    margin-bottom: 72px;
  }

  .sdds-row {
    margin-bottom: 20px;
  }

  .margin_bottom_90 {
    margin-bottom: 30px;

    .sdds-headline-04 {
      margin-bottom: 1px;
    }

    .sdds-divider-dark {
      width: 310px;
      margin-top: 1px;
    }
  }

  .h6 {
    padding-bottom: 4px !important;
    font-weight: normal !important;

  }

  .table-col {

    // margin-bottom: 64px;
    table {
      // width: 100%;
      min-width: unset !important;
    }
  }

  .pagination {
    padding-left: 0px;
  }
}