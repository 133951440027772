/* You can add global styles to this file, and also import other style files */
@import url('@scania/tegel/dist/tegel/tegel.css');
// @import '~@angular/material/prebuilt-themes/indigo-pink.css';


.ngx-pagination {
  background-color: white;

  .current {
    background: #041e42 !important;
  }
}

svg>title {
  display: none;
}

app-root {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

body {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

.contabil-page {
  //background-color: var(--sdds-grey-50) !important;
  padding-top: 90px !important;

  //margin-left: 0;
  h2 {
    margin-bottom: 32px;
  }

  .divider-line {
    border-top: 1px solid #DBDFE6;
    margin-bottom: 32px;
  }

  p {
    opacity: 0.6;
    margin-bottom: 72px;
  }

  .sdds-row {
    margin-bottom: 20px;
  }

  .margin_bottom_90 {
    margin-bottom: 30px;

    .sdds-headline-04 {
      margin-bottom: 1px;
    }

    .sdds-divider-dark {
      width: 310px;
      margin-top: 1px;
    }
  }

  .h6 {
    padding-bottom: 4px !important;
    font-weight: normal !important;

  }

  .table-col {

    // margin-bottom: 64px;
    table {
      // width: 100%;
      min-width: unset !important;
    }
  }

  .pagination {
    padding-left: 0px;
  }
}

body[data-env]:before {
  background-color: #ddd;
  display: inline-block;
  padding: 52px 50px 2px;
  content: attr(data-env);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1170;
  -ms-transform: translateY(-50%) translateX(-50%) rotate(-45deg);
  -moz-transform: translateY(-50%) translateX(-50%) rotate(-45deg);
  -webkit-transform: translateY(-50%) translateX(-50%) rotate(-45deg);
  -o-transform: translateY(-50%) translateX(-50%) rotate(-45deg);
  transform: translateY(-50%) translateX(-50%) rotate(-45deg);
  text-transform: uppercase;
  font-size: 20px;
  border: 2px solid #fafafa;
}
body[data-env=dev]:before,
body[data-env=proj]:before,
body[data-env=demo]:before,
body[data-env=proto]:before,
body[data-env=development]:before,
body[data-env^=beta]:before {
  background-color: #94a596;
  color: #fff;
}
body[data-env=sys]:before {
  background-color: #2c5234;
  color: #fff;
}
body[data-env=acc]:before,
body[data-env=pre]:before {
  background-color: #ceb888;
  color: #fff;
}
body[data-env^="2"]:before {
  background-color: #041e42;
  color: #fff;
}
body[data-env=proto]:before {
  font-size: 10px;
}
body[data-env=""]:before,
body[data-env=prod]:before,
body[data-env=production]:before,
body[data-env=hidden]:before {
  display: none;
}
body[data-env=development]:before {
  content: "dev";
}

.swal2-confirm {
  background-color: #041e42 !important;
}

.swal2-info {
  border-color: #041e42 !important;
  color: #041e42 !important;
}
